<template>
  <OForm :class="{submitting}" @submit="submit">
    <CSocialLogin @login="onSocialLogin"></CSocialLogin>
    <OFormInput
      id="email"
      name="email"
      type="email"
      v-model="form.email"
      required
      placeholder="Enter your email"
    >
      E-mail
    </OFormInput>
    <OFormInput
      id="password"
      name="password"
      type="password"
      v-model="form.password"
      autocomplete="new-password"
      required
      placeholder="Enter your password"
    >
      Password
    </OFormInput>
    <OFormTOS></OFormTOS>
    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Join
      </OFormSubmitButton>
    </FormButtons>
    <p class="info">Already have account?
      <o-link :to="{name: 'login'}">Login here</o-link>
    </p>
  </OForm>
</template>

<script>
import { mapMutations } from 'vuex'
import apiForm from '../mixins/apiForm'
import OForm from '../objects/forms/OForm'
import OFormInput from '../objects/forms/OFormInput'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import OLink from '../objects/OLink'
import FormButtons from '../objects/forms/FormButtons'
import OFormError from '../objects/forms/OFormError'
import OFormTOS from '../objects/forms/OFormTOS'
import CSocialLogin from '../components/CSocialLogin'

const getCookies = () => import('js-cookie')
const getApi = () => import('@/services/api')

export default {
  name: 'CFormRegister',
  components: { OFormTOS, OFormError, OLink, OFormSubmitButton, OFormInput, OForm, FormButtons, CSocialLogin },
  mixins: [apiForm],
  data: function () {
    return {
      form: {
        email: '',
        password: '',
        token: '',
        code: ''
      }
    }
  },
  async mounted () {
    const Cookies = await getCookies()
    if (this.$route.query.code) {
      this.form.code = this.$route.query.code
      Cookies.set('affiliate-code', this.form.code, {
        expires: 1,
        secure: true
      })
    } else {
      this.form.code = Cookies.get('affiliate-code')
    }
  },
  methods: {
    ...mapMutations(['setAuth', 'clearPhones']),
    afterLogin () {
      this.clearPhones()
    },
    async submit () {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('join')
      this.form.token = token

      this.$callApi(api => api.register(this.form))
    },
    onSocialLogin ({ social, credential }) {
      if (social === 'google') {
        this.onGoogleLogin(credential)
      } else if (social === 'apple') {
        this.onAppleLogin(credential)
      }
    },
    async onGoogleLogin (token) {
      const api = await getApi()
      const result = await api.googleLogin({ token })
      if (result.error) {
        this.error = result.error.message
        return false
      }
      this.setAuth({ email: result.data.username, password: result.data.password })
      this.afterLogin()
      this.$emit('success', result.data)
    },
    async onAppleLogin (code) {
      const api = await getApi()
      const result = await api.appleLogin({ code })
      if (result.error) {
        this.error = result.error.message
        return false
      }
      this.setAuth({ email: result.data.username, password: result.data.password })
      this.afterLogin()
      this.$emit('success', result.data)
    }
  },
  formSubmitted () {
    this.setAuth(this.form)
    this.afterLogin()
  }
}
</script>
